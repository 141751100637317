.com-classify-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}

.com-classify-header .icon {
  width: 0.2rem;
  height: 0.26rem;
  margin-right: 0.1rem;
  background: url("./icon-tag-hot.png") no-repeat 50%/cover;
}

.com-classify-header .title {
  color: #f00;
  font-weight: bold;
  font-size: 0.28rem;
}