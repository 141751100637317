.com-letter-spell {
  position: relative;
}

.com-letter-spell .letters {
  display: flex;
  justify-content: center;
  margin-bottom: 0.3rem;
}

.com-letter-spell .letters.male .letter .pinyin {
  color: #2b37c6;
}

.com-letter-spell .letters.male .letter .cnchar>svg {
  background: url("./letter-male-bg@2x.png") no-repeat 50%/cover;
}

.com-letter-spell .letters.female .letter .pinyin {
  color: #d01c1c;
}

.com-letter-spell .letters.female .letter .cnchar>svg {
  background: url("./letter-female-bg@2x.png") no-repeat 50%/cover;
}

.com-letter-spell .letters .letter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1.4rem;
  height: 1.6rem;
}

.com-letter-spell .letters .letter .pinyin {
  font-size: 0.3rem;
  line-height: 0.4rem;
  text-align: center;
  opacity: 0;
  transition: all 2s ease-in-out;
}

.com-letter-spell .letters .letter .pinyin.show {
  opacity: 1;
}