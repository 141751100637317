.com-card {
  position: relative;
  margin-bottom: 0.4rem;
}

.com-card .content {
  width: 7.2rem;
  min-height: 9.04rem;
  margin: 0 auto;
  padding: 0.16rem;
  color: #2c2c2c;
  background-color: #ffe7d3;
  border: 0.4rem solid;
  border-radius: 0.8rem;
  border-image: url("./card-bg.png") 40 40 stretch;
}

.com-card .content .item {
  position: relative;
  color: #2c2c2c;
  font-weight: bold;
  font-size: 0.28rem;
}

.com-card .content .item .imgs {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.com-card .content .item .imgs img {
  width: 50%;
}

.com-card .content .item img {
  display: block;
  width: 100%;
}