.page-order {
  overflow: hidden;
  background-color: #fcf4f3;
}

.page-order .header .x-app-header__inner {
  background-color: #f19fa6;
}

.page-order .header .x-app-header__title {
  color: #f13b3b;
  font-weight: bold;
  font-size: 0.34rem;
}

.page-order .header .back-home {
  width: 0.5rem;
  height: 0.5rem;
  background: url("./imgs/icon-back.png") no-repeat 50%/0.5rem;
}

.page-order .x-app-body {
  padding: 0;
}

.page-order .x-app-body .x-app-body__inner>.x-cell {
  margin: 0;
}

.page-order .x-app-body .nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  color: #999;
  font-size: 0.24rem;
  text-align: center;
}

.page-order .x-app-body .search {
  display: flex;
  align-items: center;
  padding: 0.2rem;
}

.page-order .x-app-body .search .btn {
  margin-left: 0.2rem;
}

.page-order .x-app-body .list .blue {
  color: #0b5;
}

.page-order .x-app-body .list .red {
  color: #f13b3b;
}

.page-order .x-app-body .list .row .info {
  padding: 0.2rem 0;
}

.page-order .x-app-body .list .row .title {
  font-weight: bold;
  font-size: 0.32rem;
}

.page-order .x-app-body .list .row .order {
  color: #666;
  font-size: 0.28rem;
}

.page-order .x-app-body .list .row .time {
  color: #999;
  font-size: 0.24rem;
}