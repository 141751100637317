.pop-red-pack .x-modal__inner {
  background: url("./icon-hongbao.png") no-repeat 50%/cover;
}

.pop-red-pack .x-modal__inner .x-dialog__inner {
  position: relative;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner {
  padding-top: 1rem;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner h1 {
  color: #e38000;
  font-size: 0.5rem;
  text-align: center;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner h2 {
  color: #f00;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner h2 sup {
  color: #f00;
  font-weight: bold;
  font-size: 0.4rem;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner h6 {
  font-size: 0.3rem;
  text-align: center;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .countdown {
  margin-top: 2.2rem;
  color: #fff;
  font-size: 0.4rem;
  text-align: center;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .btns {
  margin: 0.1rem 0;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .btns .btn {
  margin: 0.3rem 0;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .btns .btn.ok {
  font-size: 0.4rem;
  background-color: #f99e08;
  animation: sharke 1s linear infinite;
}

.pop-red-pack .x-modal__inner .x-dialog__inner .inner .btns .btn.cancel {
  color: #f7b352;
  font-size: 0.4rem;
  border: solid 0.02rem #f7b352;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}