.com-fake-bubble {
  position: fixed;
  bottom: 1.4rem;
  z-index: 999;
  width: 100%;
  pointer-events: none;
}

.com-fake-bubble .content {
  display: none;
  width: 5rem;
  height: 0.7rem;
  margin: 0 auto;
  color: #fff;
  font-weight: bold;
  font-size: 0.28rem;
  line-height: 0.7rem;
  text-align: center;
  background-color: rgba(0,0,0,0.7);
  border-radius: 0.35rem;
}

.com-fake-bubble .content.show {
  display: block;
}