.page-pay {
  background-color: #ff611a;
}

.page-pay .body {
  padding: 0;
  padding-bottom: 1.6rem;
}

.page-pay .body .banner {
  margin-top: -0.5rem;
}

.page-pay .body .banner img {
  display: block;
  width: 100%;
}

.page-pay .body .names {
  position: relative;
  z-index: 9;
  width: 7.2rem;
  height: 7.4rem;
  margin: 0 auto;
  margin-top: -2.7rem;
  padding-top: 0.4rem;
  background: url("./imgs/name-bg@2x.png") no-repeat 50% 0/cover;
}

.page-pay .body .names .scores {
  position: absolute;
  bottom: 0.2rem;
  left: 50%;
  width: 5.8rem;
  margin-left: -2.9rem;
}

.page-pay .body .names .scores .circle {
  position: absolute;
  width: 1.46rem;
  height: 1.42rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.26rem;
  line-height: 1.42rem;
  text-align: center;
  text-shadow: 0 0 0.06rem #ff00eb;
  background: url("./imgs/circle@2x.png") no-repeat 50%/cover;
}

.page-pay .body .names .scores .circle.circle1 {
  bottom: 1.2rem;
  left: 0;
}

.page-pay .body .names .scores .circle.circle2 {
  bottom: 0.4rem;
  left: 50%;
  margin-left: -0.8rem;
}

.page-pay .body .names .scores .circle.circle3 {
  right: 0;
  bottom: 1.2rem;
}

.page-pay .body .names .scores .tip {
  position: relative;
  width: 100%;
  padding: 0 0.4rem;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.34rem;
  line-height: 0.48rem;
  text-align: center;
}

.page-pay .body .names .scores .tip::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.38rem;
  height: 0.48rem;
  background: url("./imgs/flower@2x.png") no-repeat 50%/0.38rem;
  content: '';
}

.page-pay .body .names .scores .tip::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.38rem;
  height: 0.48rem;
  background: url("./imgs/flower@2x.png") no-repeat 50%/0.38rem;
  content: '';
}

.page-pay .body .names .scores .star {
  position: absolute;
  bottom: 1.3rem;
  left: 50%;
  z-index: 9;
  width: 2.2rem;
  height: 2.2rem;
  margin-left: -1.16rem;
  padding-top: 0.6rem;
  background: url("./imgs/star-score@2x.png") no-repeat 50%/2.13rem 2.07rem;
}

.page-pay .body .names .scores .star .score {
  color: #fff;
  font-weight: 400;
  font-size: 0.56rem;
  line-height: 0.72rem;
  text-align: center;
  text-indent: -0.1rem;
  text-shadow: 0 0 0.06rem #ff00eb;
}

.page-pay .body .names .scores .star .score span {
  color: #fff;
  font-weight: 400;
  font-size: 0.56rem;
  text-shadow: 0 0 0.06rem #ff00eb;
}

.page-pay .body .names .scores .star .txt {
  margin-top: -0.05rem;
  color: #fff;
  font-size: 0.22rem;
  line-height: 0.3rem;
  text-align: center;
  text-indent: -0.08rem;
}

.page-pay .body .pay-panel .price {
  padding: 0.3rem 0;
  border-top: 0.02rem solid #ccc;
  border-bottom: 0.02rem solid #ccc;
}

.page-pay .body .pay-panel .price .discount {
  display: flex;
  justify-content: space-between;
  color: #9d5028;
}

.page-pay .body .pay-panel .price .discount .original {
  text-decoration: line-through;
}

.page-pay .body .pay-panel .price .discount span {
  color: inherit;
}

.page-pay .body .pay-panel .price .current {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.46rem;
}

.page-pay .body .pay-panel .price .current span {
  display: block;
  flex-shrink: 0;
  margin-right: 0.1rem;
  padding: 0.1rem;
  color: #fff;
  font-size: 0.2rem;
  background: #fd6622;
  border-radius: 0.26rem;
}

.page-pay .body .pay-panel .price .current em {
  color: #ef0c0c;
  font-weight: inherit;
  font-size: inherit;
  font-style: normal;
  letter-spacing: -0.01rem;
}

.page-pay .body .pay-panel .price .current .countdown {
  position: absolute;
  right: 0;
  color: #ef0c0c;
  font-weight: inherit;
  font-size: inherit;
}

.page-pay .body .pay-panel .desc {
  padding: 0.4rem 0;
  color: #2c2c2c;
  font-weight: bold;
  font-size: 0.32rem;
  letter-spacing: -0.005rem;
}

.page-pay .body .pay-panel .desc em {
  color: #de591c;
  font-style: normal;
}

.page-pay .body .pay-panel .locks {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 -0.2rem;
}

.page-pay .body .pay-panel .locks .lock {
  display: flex;
  align-items: flex-end;
  width: 1.9rem;
  height: 2.02rem;
  padding-bottom: 0.32rem;
  font-size: 0.2rem;
  background: url("./imgs/lock-yellow@2x.png") no-repeat 50%/cover;
}

.page-pay .body .pay-panel .locks .lock.lock1 {
  animation: infiniteTrans 2s ease-in-out infinite;
}

.page-pay .body .pay-panel .locks .lock.lock2 {
  animation: infiniteTrans 2s ease-in-out infinite 0.4s;
}

.page-pay .body .pay-panel .locks .lock.lock3 {
  animation: infiniteTrans 2s ease-in-out infinite;
}

.page-pay .body .pay-panel .locks .lock.lock4 {
  animation: infiniteTrans 2s ease-in-out infinite 0.6s;
}

.page-pay .body .pay-panel .locks .lock.lock5 {
  animation: infiniteTrans 2s ease-in-out infinite 0.1s;
}

.page-pay .body .pay-panel .locks .lock.lock6 {
  animation: infiniteTrans 2s ease-in-out infinite 0.8s;
}

.page-pay .body .pay-panel .locks .lock.lock7 {
  animation: infiniteTrans 2s ease-in-out infinite;
}

.page-pay .body .pay-panel .locks .lock .txt {
  width: 100%;
  color: #fff;
  text-align: center;
}

.page-pay .body .pay-panel .locks .lock.purple {
  background: url("./imgs/lock-purple@2x.png") no-repeat 50%/cover;
}

.page-pay .body .pay-panel .locks .lock.big {
  width: 2.36rem;
  height: 2.5rem;
  padding-bottom: 0.36rem;
  font-size: 0.26rem;
}

.page-pay .body .pay-panel .locks .lock.small {
  width: 1.3rem;
  height: 1.38rem;
  padding-bottom: 0.22rem;
  font-size: 0.16rem;
}

.page-pay .body .payways {
  padding: 0.4rem 0;
}

.page-pay .body .payways .payway {
  position: relative;
  display: flex;
  align-items: center;
  height: 0.72rem;
  margin-bottom: 0.4rem;
}

.page-pay .body .payways .payway:last-child {
  margin-bottom: 0;
}

.page-pay .body .payways .payway .icon {
  width: 0.72rem;
  height: 0.72rem;
  margin-right: 0.2rem;
}

.page-pay .body .payways .payway .name {
  color: #2c2c2c;
  font-weight: bold;
  font-size: 0.28rem;
}

.page-pay .body .payways .payway.wechat .icon {
  background: url("./imgs/icon-wechat@2x.png") no-repeat 50%/0.72rem;
}

.page-pay .body .payways .payway.alipay .icon {
  background: url("./imgs/icon-alipay@2x.png") no-repeat 50%/0.72rem;
}

.page-pay .body .payways .payway .check {
  position: absolute;
  right: 0;
  width: 0.72rem;
  height: 0.72rem;
  background: url("./imgs/icon-check@2x.png") no-repeat 50%/0.32rem;
}

.page-pay .body .payways .payway.checked .check {
  background: url("./imgs/icon-checked@2x.png") no-repeat 50%/0.32rem;
}

.page-pay .body .submit {
  width: 6.8rem;
  height: 2.25rem;
  margin: -0.4rem -0.34rem;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.46rem;
  background: url("../../assets/imgs/bg-btn.png") no-repeat 50%/contain;
  animation: sharke 1s linear infinite;
}

.page-pay .body .submit:active {
  opacity: 1;
}

.page-pay .process-animation {
  margin: 0.4rem 0.5rem;
  padding: 0.4rem;
  color: #d86a2b;
  background-color: #fff;
  border-radius: 0.2rem;
}

.page-pay .process-animation .title {
  color: #e7905e;
  font-weight: bold;
  font-size: 0.32rem;
  text-align: center;
}

.page-pay .process-animation .process {
  position: relative;
  width: 100%;
  height: 0.1rem;
  margin-top: 0.3rem;
  overflow: hidden;
  background-color: #f2d3c1;
  border-radius: 0.05rem;
}

.page-pay .process-animation .process.start {
  width: 100%;
}

.page-pay .process-animation .process.start .bar {
  width: 100%;
}

.page-pay .process-animation .process .bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0.1rem;
  background-color: #d86a2b;
  animation: process 1.5s linear;
}

.page-pay .fixed-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: rgba(152,50,5,0.9);
}

.page-pay .fixed-btn .fixed-submit {
  width: 7.5rem;
  height: 2.25rem;
  margin: 0 auto;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.46rem;
  background: url("../../assets/imgs/bg-btn.png") no-repeat 50%/cover;
  animation: sharke 1s linear infinite;
}

.page-pay .fixed-btn .fixed-submit:active {
  opacity: 1;
}

@keyframes infiniteTrans {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.14rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes process {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}