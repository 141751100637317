.com-footer {
  padding-bottom: 1rem;
}

.com-footer h2 {
  color: #fff07e;
  font-size: 0.4rem;
  text-align: center;
}

.com-footer .cx {
  width: 3.78rem;
  height: 0.64rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  background: url("./cxs.png") no-repeat 50%/cover;
}

.com-footer .company {
  color: #e56767;
  font-size: 0.2rem;
  text-align: center;
}

.com-footer .tip-warning {
  color: #b1afaf;
  font-size: 0.2rem;
  text-align: center;
}