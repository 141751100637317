.com-content-card {
  position: relative;
  margin-bottom: 0.4rem;
}

.com-content-card-title {
  position: relative;
  z-index: 9;
  width: 4.58rem;
  height: 1.22rem;
  margin: 0 auto;
  color: #964000;
  font-weight: bold;
  font-size: 0.36rem;
  line-height: 1.1rem;
  text-align: center;
  background: url("./title.png") no-repeat 50%/cover;
}

.com-content-card-content {
  position: relative;
  width: 100%;
  min-height: 5.56rem;
  margin-top: -0.6rem;
  padding: 0.5rem 0.26rem 0.26rem 0.26rem;
  overflow: hidden;
  background-color: #f4cfb1;
  border-radius: 0.2rem;
}

.com-content-card-content.lock {
  background: url("./blur-bg.png") no-repeat 50%/100% 100%;
}

.com-content-card-content.lock .p {
  opacity: 0;
}

.com-content-card-btn {
  position: absolute;
  bottom: 0.38rem;
  left: 50%;
  z-index: 9;
  width: 3.3rem;
  height: 0.74rem;
  margin-left: -1.65rem;
  padding-left: 0.45rem;
  color: #fff;
  font-weight: bold;
  line-height: 0.6rem;
  text-align: center;
  background: url("./btn.png") no-repeat 50%/cover;
}

.com-content-card-lock-tips {
  position: absolute;
  top: 1.6rem;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 4.96rem;
  height: 2.26rem;
  margin-left: -2.48rem;
  padding: 0.2rem;
  color: #fff;
  font-weight: bold;
  background: #c14949;
  border: 0.04rem solid #ffb97e;
  border-radius: 0.18rem 0.18rem 0.18rem 0.18rem;
  box-shadow: 0 0.06rem 0.12rem 0.02rem rgba(0,0,0,0.16);
  opacity: 1;
}

.com-content-card-lock-tips .p {
  position: relative;
  width: auto;
  padding-left: 0.25rem;
  color: #fff;
  font-size: 0.28rem;
  line-height: 0.4rem;
}

.com-content-card-lock-tips .p em {
  color: #ffb97e;
  font-style: normal;
}

.com-content-card-lock-tips .p::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.16rem;
  height: 0.4rem;
  background: url("./star.png") no-repeat 50%/0.16rem 0.2rem;
  content: '';
}