.page-result {
  background-color: #ff611a;
}

.page-result .body {
  padding: 0;
  padding-bottom: 0.5rem;
}

.page-result .body h2 {
  margin: 0.2rem 0;
  color: #c11316;
  text-align: center;
}

.page-result .body .txt {
  color: #2c2c2c;
  font-weight: bold;
  font-size: 0.28rem;
  line-height: 0.5rem;
}

.page-result .body .txt em {
  color: #000;
  font-weight: bold;
  font-style: normal;
}

.page-result .body .txt em.b {
  color: #135bb6;
}

.page-result .body .txt em.g {
  color: #e15c22;
}

.page-result .body .gua-wrapper .title {
  width: 2.62rem;
  height: 0.43rem;
  margin: 0 auto;
  background: url("./imgs/gua-title.png") no-repeat 50%/cover;
}

.page-result .body .gua-wrapper .gua {
  display: flex;
  justify-content: space-between;
  width: 4rem;
  margin: 0.5rem auto;
}

.page-result .body .gua-wrapper .gua span {
  display: block;
  color: #c10b0b;
  font-weight: bold;
  font-size: 0.32rem;
}

.page-result .body .gua-wrapper .img {
  width: 3rem;
  margin: 0 auto 0.4rem auto;
}

.page-result .body .gua-wrapper .img img {
  display: block;
  width: 100%;
}

.page-result .body .gua-wrapper .names {
  display: flex;
  justify-content: space-between;
  width: 4.4rem;
  margin: 0 auto 0.4rem auto;
  font-weight: bold;
  font-size: 0.32rem;
}

.page-result .body .gua-wrapper .names .b {
  color: #135bb6;
}

.page-result .body .gua-wrapper .names .g {
  color: #e15c22;
}

.page-result .body .scores {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
}

.page-result .body .scores .man {
  width: 1.46rem;
  height: 1.46rem;
}

.page-result .body .scores .man.male {
  background: url("./imgs/male.png") no-repeat 50%/cover;
}

.page-result .body .scores .man.female {
  background: url("./imgs/female.png") no-repeat 50%/cover;
}

.page-result .body .scores .score {
  width: 2.44rem;
  height: 2.14rem;
  margin: 0 0.14rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.82rem;
  font-family: Alibaba Sans-Medium, Alibaba Sans;
  line-height: 1.6rem;
  text-align: center;
  text-shadow: 0 0 0.12rem #f00;
  background: url("./imgs/heart.png") no-repeat 50%/cover;
  background-clip: text;
  -webkit-text-fill-color: transparen;
}

.page-result .ads {
  position: relative;
  display: block;
  padding: 0 0.4rem 0.4rem 0.4rem;
  overflow: hidden;
  border-radius: 0.05rem;
}

.page-result .ads .ad {
  display: block;
  margin-bottom: 0.3rem;
}

.page-result .ads .ad.name {
  animation: sharke 2s linear infinite;
}

.page-result .ads .ad.yinyuan {
  animation: sharke 2s linear 1s infinite;
}

.page-result .ads .ad.qinggan {
  animation: sharke 2s linear 1.6s infinite;
}

.page-result .ads img {
  display: block;
  width: 100%;
  border-radius: 0.05rem;
}

.page-result .ads h2 {
  position: absolute;
  bottom: 0.3rem;
  width: 100%;
  color: #fff;
  font-size: 0.36rem;
  text-align: center;
}

.page-result .submit {
  width: 6.8rem;
  height: 2.25rem;
  margin: -0.4rem auto;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.46rem;
  background: url("../../assets/imgs/bg-btn.png") no-repeat 50%/contain;
  animation: sharke 1s linear infinite;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes circleAnimationn {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}