.com-steps {
  position: relative;
}

.com-steps .process-animation {
  margin: 0.4rem 0.5rem;
  padding: 0.4rem;
  color: #d86a2b;
  background-color: #fff;
  border-radius: 0.2rem;
}

.com-steps .process-animation .title {
  color: #e7905e;
  font-weight: bold;
  font-size: 0.32rem;
  text-align: center;
}

.com-steps .process-animation .process {
  position: relative;
  width: 100%;
  height: 0.1rem;
  margin-top: 0.3rem;
  overflow: hidden;
  background-color: #f2d3c1;
  border-radius: 0.05rem;
}

.com-steps .process-animation .process.start {
  width: 100%;
}

.com-steps .process-animation .process.start .bar {
  width: 100%;
}

.com-steps .process-animation .process .bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0.1rem;
  background-color: #d86a2b;
  animation: process 1.5s linear;
}

@keyframes process {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}