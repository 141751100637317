.page-index {
  overflow: hidden;
  background-color: #fd6120;
}

.page-index .x-app-body {
  padding: 0;
  padding-bottom: 1.2rem;
  overflow-x: hidden;
}

.page-index .slogan {
  position: relative;
  width: 100%;
  height: 6.32rem;
  overflow: hidden;
  background: url("./imgs/bg-slogan.png") no-repeat 50%/cover;
}

.page-index .bjx-wrapper {
  position: relative;
  margin-top: -2.4rem;
}

.page-index .bjx-wrapper .photo {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 8.28rem;
  background: url("./imgs/bg-photo.png") no-repeat 50%/cover;
}

.page-index .bjx-wrapper .bjx {
  position: absolute;
  top: 0.4rem;
  left: 50%;
  z-index: 1;
  width: 6.82rem;
  height: 6.82rem;
  margin-left: -3.41rem;
  background: url("./imgs/bjx.png") no-repeat 50%/cover;
  animation: bxj 10s linear infinite;
}

.page-index .form {
  position: relative;
  z-index: 10;
  margin-top: -0.8rem;
}

.page-index .form .input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.2rem;
}

.page-index .form .input-wrapper .item {
  width: 3.08rem;
  height: 1.08rem;
  padding: 0 0.4rem;
  overflow: hidden;
}

.page-index .form .input-wrapper .item>input {
  display: block;
  width: 100%;
  height: 0.98rem;
  font-weight: bold;
  font-size: 0.28rem;
  background: none;
  border: none;
  outline: 0;
  -webkit-appearance: none;
}

.page-index .form .input-wrapper .item.male {
  margin-right: -0.1rem;
  background: url("./imgs/bg-input-male.png") no-repeat 50%/cover;
}

.page-index .form .input-wrapper .item.female {
  margin-left: -0.1rem;
  background: url("./imgs/bg-input-female.png") no-repeat 50%/cover;
}

.page-index .form .input-wrapper .heart {
  width: 1rem;
  height: 1rem;
  background: url("./imgs/icon-heart.png") no-repeat 50%/cover;
}

.page-index .form .agreement {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  margin-top: -0.4rem;
  font-size: 0.28rem;
}

.page-index .form .agreement .text {
  padding: 0.1rem 0;
  color: #fff;
  line-height: 0.32rem;
}

.page-index .form .agreement .text a {
  color: #fff07e;
  text-decoration: underline;
}

.page-index .form .agreement .checkbox .x-radio__icon {
  background: none;
  border: solid 0.02rem #fff;
}

.page-index .form .agreement .checkbox.x-radio--checked .x-radio__icon {
  background: none;
}

.page-index .btns {
  margin-top: -0.2rem;
}

.page-index .btns .submit {
  width: 7.5rem;
  height: 2.25rem;
  margin: 0 auto;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.46rem;
  background: url("../../assets/imgs/bg-btn.png") no-repeat 50%/cover;
  animation: sharke 1s linear infinite;
}

.page-index .btns .tip {
  margin-top: 0.1rem;
  padding: 0.1rem 0;
  color: #fff;
  text-align: center;
}

.page-index .btns .tip em {
  color: #fff07e;
  font-weight: bold;
  font-style: normal;
}

.page-index .btns .order {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0.4rem;
  font-size: 0.3rem;
  text-align: center;
}

.page-index .btns .order a {
  color: #fff;
  text-decoration: underline;
}

.page-index .card-jxpdzs .content {
  padding-top: 0.8rem;
}

.page-index .card-jxpdzs .item {
  margin-bottom: 0.34rem;
}

.page-index .card-jxpdzs .item .desc {
  display: flex;
  align-items: center;
  width: 5.88rem;
  height: 2.2rem;
  margin: 0 auto;
  padding: 0 0.38rem;
  font-size: 0.36rem;
  border-radius: 0.22rem;
}

.page-index .card-jxpdzs .item .img {
  position: absolute;
  top: -0.58rem;
  width: 3.26rem;
  height: 3.26rem;
}

.page-index .card-jxpdzs .item.tm {
  margin-bottom: 1.02rem;
}

.page-index .card-jxpdzs .item.tm .desc {
  justify-content: flex-end;
  color: #ef3a08;
  background-color: rgba(199,86,86,0.3);
}

.page-index .card-jxpdzs .item.tm .img {
  left: 0.38rem;
  background: url("./imgs/tm.png") no-repeat 50%/cover;
}

.page-index .card-jxpdzs .item.md .desc {
  justify-content: flex-start;
  color: #596e4c;
  background-color: rgba(86,199,147,0.3);
}

.page-index .card-jxpdzs .item.md .img {
  right: 0.38rem;
  background: url("./imgs/md.png") no-repeat 50%/cover;
}

.page-index .card-jxpdzs .item.sx {
  color: #2c2c2c;
  font-size: 0.32rem;
  line-height: 0.6rem;
}

.page-index .card-jxpdzs .item.sx em {
  color: #de5914;
  font-style: normal;
}

.page-index .fixed-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: rgba(152,50,5,0.9);
}

.page-index .fixed-btn .fixed-submit {
  width: 7.5rem;
  height: 2.25rem;
  margin: 0 auto;
  color: #d86a2b;
  font-weight: bold;
  font-size: 0.46rem;
  background: url("../../assets/imgs/bg-btn.png") no-repeat 50%/cover;
  animation: sharke 1s linear infinite;
}

.page-index .fixed-btn .fixed-submit:active {
  opacity: 1;
}

@keyframes bxj {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}