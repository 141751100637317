.com-title {
  position: relative;
}

.com-title .inner {
  width: 7.5rem;
  height: 1.3rem;
  margin: 0 auto;
  color: #de5914;
  font-weight: bold;
  font-size: 0.4rem;
  line-height: 1.34rem;
  text-align: center;
  background: url("./title-bg.png") no-repeat 50%/cover;
}